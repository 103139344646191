import React from "react";
import Modal from "../../../templates/Modal";
import Button from "../../atoms/Button";
import { Container, Title } from "./styled";

export default function Alert(props) {
    const { title, buttonTxt, onClick } = props;
    return (
        <Modal>
            <Container>
                <Title>{title}</Title>
                <Button text={buttonTxt} onClick={onClick} width="150px" />
            </Container>
        </Modal>
    );
}

Alert.defaultProps = {
    title: "",
    buttonTxt: "",
    onClick: () => { }
}