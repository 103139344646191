import React, { Fragment, useState } from "react";
import { useLanguageContext } from "../../../contexts/LanguageContext";
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import Language from "../../../../localization/Language";

const ITEM_HEIGHT = 48;

export default function LanguageHandler() {
  const { language, changeLanguage } = useLanguageContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onChangeHandler = (value) => {
    changeLanguage(value);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const selLanguage = Language.find(x => x.id === language);

  return (
    <Fragment>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? 'language-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <Avatar sx={{ width: 32, height: 32 }} src={selLanguage.image} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="language-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 1,
          sx: {
            overflow: 'auto',
            maxHeight: ITEM_HEIGHT * 4.5,
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 0.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {Language.map((element, index) => {
          return (
            <MenuItem key={index.toString()} onClick={() => onChangeHandler(element.id)}>
              <Avatar sx={{ width: 24, height: 24 }} alt={element.id} src={element.image} />
              {element.name}
            </MenuItem>
          );
        })}
      </Menu>
    </Fragment>
  );
}
