import styled from "styled-components";

const Container = styled.div`
    display: block;
    width: 80%;
    margin: auto;
    background: #1d1d1d;
    padding: 30px;
    border-radius: 8px;
`;

export { Container };