const language = {
  requiredErrorTxt: 'The field is required',
  okButtonTxt: "OK",
  header: {
    title: 'EA Atomic Arista - Traders Community',
    buyPackage: 'Buy Package',
    registerBroker: "Broker Registration",
    registerNaf1: "NAF1 Registration",
    binaryTreeTxt: "Binary Tree"
  },
  home: {
    title: "WELCOME TO EA Atomic Arista - Traders Community",
    subTitle: "Becoming a trader is a promising profession during the pandemic and in the future. Where we have provided an easy and profitable EA for you great traders. Where for today to start becoming a trader is very easy without us having to always monitor market movements. we only need the help of automated systems or robots which are often called Expert Advisors (EA) for start trading.",
    zfxTitle: "REGISTER ZFX ACCOUNT",
    forexTitle: "REGISTER JUSTFOREX ACCOUNT",
    fbsTitle: "REGISTER FBS ACCOUNT",
    registerSubTitle: "Please register via the link below. After entering the page, make sure you fill in your personal data completely:",
    registerFroms: [
      {
        name: "ZFX",
        values: [
          {
            key: "Name : ",
            value: "As per ID/Passport"
          },
          {
            key: "Phone Number : ",
            value: "Active Number",
          },
          {
            key: "Email : ",
            value: "Active Email",
          }
        ]
      },
      {
        name: "JUST FOREX",
        values: [
          {
            key: "Name : ",
            value: "As per ID/Passport"
          },
          {
            key: "Phone Number : ",
            value: "Active Number",
          },
          {
            key: "Email : ",
            value: "Active Email",
          }
        ]
      },
      {
        name: "FBS",
        values: [
          {
            key: "Name : ",
            value: "As per ID/Passport"
          },
          {
            key: "Phone Number : ",
            value: "Active Number",
          },
          {
            key: "Email : ",
            value: "Active Email",
          }
        ]
      }
    ],
    registerFootNotes: "*After completing registration, the system will send the login password and Transaction broker to the register email",
    registerBtnTxt: "Register",
    packages: [
      {
        name: "EA XAU Atomic",
        priceTxt: "Free EA bot $2100",
        price: 15000,
        minDepositTxt: "Min Deposit $15000",
        maxDepositTxt: "Max Deposit $90000",
        startFromTxt: "Start from $15000",
        buttonTxt: "EA XAU Atomic",
        description: `XAU Atomic EA is introduced as “The Most Powerful Automated Trading Strategy”. It can work with seven pairs. The system makes profits by performing orders based on the strengths and weaknesses of YEN, USD, GBP, EURO, and Gold.`
      },
      {
        name: "EA Correlation Atomic",
        priceTxt: "Free EA bot $700",
        price: 10000,
        minDepositTxt: "Min Deposit $10000",
        maxDepositTxt: "Max Deposit $15000",
        startFromTxt: "Start from $10000",
        buttonTxt: "EA Correlation",
        description:`Every currency has directly or indirectly correlation between them which could be positive or negative depending upon currency which can be measured from -100% to 100%.
        Where -100% means currencies moves exactly opposite to each other where as 100% means currencies moves in same direction and in middle range they corelate as per percent corelation in between them.
        This correlation between pairs keeps changing its range which could vary from negative to positive & positive to negative.
        Now depending upon the type of correlation between two pairs our Correlation EA will place place trades as BUY-SELL or SELL-BUY
        and waits for collective profits of it and once threshold is reached EA closes both the trades`
      },
      {
        name: "EA XAU Manifest",
        priceTxt: "Free EA bot $350",
        price: 5000,
        minDepositTxt: "Min Deposit $5000",
        maxDepositTxt: "Max Deposit $10000",
        startFromTxt: "Start from $5000",
        buttonTxt: "EA XAU Manifest",
        description: `is an Expert Advisor designed specifically for trading Gold H1.
        It use some indicators to find the good Entry. And you can set SL or you can DCA if you want.
        It can be an Scalping or an Grid/ Martingale depend yours setting.
        This EA can Auto lot by Balance, set risk per trade. You also can set TP/ SL for earch trade or for basket of trade.`
      },
      {
        name: "EA Balmonic Semi Auto",
        priceTxt: "Free EA bot $350",
        price: 3000,
        minDepositTxt: "Min Deposit $3000",
        maxDepositTxt: "Max Deposit $5000",
        startFromTxt: "Start from $3000",
        buttonTxt: "EA Balmonic",
        description: `indicators used MACD, EMA, and envelope
        all OPs are based on the big trend seen from MACD and EMA`
      }
    ],
    affiliate: {
      title: "Affiliate System",
      description: "The system used for marketing NAF1Team is a tiered marketing system with the AFFILIATE concept, where a direct seller may have as many direct CLIENTS as they need.",
      list: [
        "Affiliate Level 1 get 10%",
        "Affiliate Level 2 get 5%",
        "Affiliate Level 3 get 3%",
        "Affiliate Level 4 get 2%"
      ]
    }
  },
  register: {
    title: "Registration",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone",
    referall: "Referall Code",
    amount: "Deposit",
    tradingTxt: "MT4 ID",
    tradingPasswordTxt: "Trading Password from Email",
    investorPasswordTxt: "Investor Password from Email",
    serverTxt: "Server",
    brokerTxt: "Broker",
    passwordPortalTxt: "Password Portal Broker",
    submitBtnTxt: "Submit",
    cancelBtnTxt: "Cancel",
    registerSuccessTxt: "Registration completed successfully",
    aggrementTxt: "I agree to send this information to be submitted to Team NAF1 for bot configuration on the trading server."
  },
  payment: {
    title: "Payment",
    submitBtnTxt: "Make Payment",
    aggrementTxt: "I agree to pay amount $amount to buy software.",
    paymentSuccessTxt: "Payment completed successfully",
    forms: {
      packageNameTxt: "Package Name",
      priceTxt: "Price",
      paymentMethodTxt: "Payment Method",
      usdtTxt: "USDT",
      localBankTxt: "Local Bank",
      usdtDescTxt: "Tether (USDT) Cryptocurrency Payment<br/>Open your cryptocurrency wallet, enter (ADDRESS Wallet <b>$id</b>) and transfer $amount USDT.<br/>Your fund transfer may take from within an hour up to 1 business day.",
      depositTxt: "Deposit Amount",
      totalTransferTxt: "Total Transfer",
      linkTransferTxt: "Link Payment Receipt",
      fullNameTxt: "Name",
      emailTxt: "Email",
      phoneNumberTxt: "Phone",
      buyerCountryTxt: "Buyer Country",
      bankAccountTxt: "Bank Account",
      linkTransferDescTxt: "*Upload your transfer receipt <a href='https://imgbb.com' target='_blank'>here</a> and copy paste the link into link payment receipt field."
    }
  },
  broker: {
    brokerList: [
      {
        name: "ZFX",
        values: [
          {
            key: "",
            value: "Register using your detail profile"
          },
          {
            key: "",
            value: "Login in MY ZFX"
          },
          {
            key: "",
            value: "Click Trading Account",
          },
          {
            key: "",
            value: "Choose New Account",
          },
          {
            key: "",
            value: "Choose MT4",
          },
          {
            key: "",
            value: "Choose Standar Cent",
          },
          {
            key: "",
            value: "Choose Open Real Account",
          },
          {
            key: "",
            value: "Currency USD",
          },
          {
            key: "",
            value: "Leverage 1:2000",
          },
          {
            key: "",
            value: "Setting Trading Password",
          },
          {
            key: "",
            value: "Choose Create Account",
          },
          {
            key: "",
            value: "Input to Form Online",
            options: [
              {
                key: "",
                value: "Login MY ZFX",
                options: [
                  {
                    key: "",
                    value: "Email",
                  },
                  {
                    key: "",
                    value: "Password",
                  }
                ]
              },
              {
                key: "",
                value: "Trading Account MT4 Cent",
                options: [
                  {
                    key: "",
                    value: "Server - ZealCapitalMarketSC-Live",
                  },
                  {
                    key: "",
                    value: "User Id",
                  },
                  {
                    key: "",
                    value: "Password Trader",
                  },
                  {
                    key: "",
                    value: "Password Investor",
                  }
                ]
              }
            ]
          },
          {
            key: "",
            value: "Verify Account with ID Card and Bank Info Detail",
          },
          {
            key: "",
            value: "Deposit on ZFX",
          }
        ]
      },
      {
        name: "JUST FOREX",
        values: [
          {
            key: "",
            value: "Register using your detail profile"
          },
          {
            key: "",
            value: "Click my account"
          },
          {
            key: "",
            value: "Choose new account"
          },
          {
            key: "",
            value: "Choose MT4"
          },
          {
            key: "",
            value: "Choose standar cent"
          },
          {
            key: "",
            value: "Choose open real account"
          },
          {
            key: "",
            value: "Currency USC"
          },
          {
            key: "",
            value: "Leverage 1:1000"
          },
          {
            key: "",
            value: "Setting Trading Password"
          },
          {
            key: "",
            value: "Choose Create Account"
          },
          {
            key: "",
            value: "Input to Form Online",
            options: [
              {
                key: "",
                value: "Login Just Forex",
                options: [
                  {
                    key: "",
                    value: "Email"
                  },
                  {
                    key: "",
                    value: "Password"
                  }
                ]
              }
            ]
          },
          {
            key: "",
            value: "Trading Account MT4 Cent",
            options: [
              {
                key: "",
                value: "Server - JustForex-Live"
              },
              {
                key: "",
                value: "User Id"
              },
              {
                key: "",
                value: "trader Password"
              },
              {
                key: "",
                value: "Investor Password"
              },
            ]
          },
          {
            key: "",
            value: "Verify Account with ID Card and Bank Info Detail"
          },
          {
            key: "",
            value: "Deposit on Just Forex"
          },
        ]
      },
      {
        name: "FBS",
        values: [
          {
            key: "",
            value: "Choose Create Account"
          },
          {
            key: "",
            value: "Choose New Account"
          },
          {
            key: "",
            value: "Create Cent Account"
          },
          {
            key: "",
            value: "Don't Activate Fixrate"
          },
          {
            key: "",
            value: "Leverage 1:1000"
          },
          {
            key: "",
            value: "Verify ID and Email"
          },
          {
            key: "",
            value: "Depoit",
            oprions: [
              {
                key: "",
                value: "Login FBS",
                options: [
                  {
                    key: "",
                    value: "Email"
                  },
                  {
                    key: "",
                    value: "Password"
                  },
                ]
              },
            ]
          },
          {
            key: "",
            value: "Trading Account MT4 Cent",
            options: [
              {
                key: "",
                value: "Server"
              },
              {
                key: "",
                value: "User Id"
              },
              {
                key: "",
                value: "Trader Password"
              },
              {
                key: "",
                value: "Investor Password"
              },
            ]
          },
        ]
      }
    ]
  },
  platforms: {
    metatrader4Txt: "METATRADER 4",
    forPCTxt: "FOR WINDOWS",
    forAndroidTxt: "FOR ANDROID",
    forIOSTxt: "FOR IOS / IPHONE",
    forMacTxt: "FOR MAC",
    forNaf1AndroidTxt: "FOR NAF1 Android",
    forNaf1IOSTxtx: "FOR NAF1 IOS",
    downloadTxt: "DOWNLOAD"
  },
  profit: {
    title: "Profit",
    disclamerTxt: "Profits can change at any time, depending on market conditions."
  }
};

export default language;
