import ZFXLogo from "../../../assets/Z1.JPG"
import ForexLogo from "../../../assets/j1.JPG"
import FBSLogo from "../../../assets/f1.JPG";
import StarterProfit from "../../../assets/profit/starter.JPG";
import SilverProfit from "../../../assets/profit/silver.JPG";
import GoldProfit from "../../../assets/profit/gold.JPG";
import PlatinumProfit from "../../../assets/profit/platinum.JPG";
import RoyalProfit from "../../../assets/profit/royal.JPG";
import Profit1 from "../../../assets/profit/profit_1.jpg";
import Profit2 from "../../../assets/profit/profit_2.jpg";
import Profit3 from "../../../assets/profit/profit_3.jpg";
import Profit4 from "../../../assets/profit/profit_4.jpg";
import Constants from "../../Constants";

const profitList = [
    {
        label: 'starter',
        imgUrl: StarterProfit,
    },
    {
        label: 'Silver',
        imgUrl: SilverProfit
    },
    {
        label: 'Gold',
        imgUrl: GoldProfit
    },
    {
        label: 'Platinum',
        imgUrl: PlatinumProfit
    },
    {
        label: 'Royal',
        imgUrl: RoyalProfit
    }
];

const profitList2 = [
    {
        label: 'profit1',
        imgUrl: Profit1,
    },
    {
        label: 'profit2',
        imgUrl: Profit2,
    },
    {
        label: 'profit3',
        imgUrl: Profit3,
    },
    {
        label: 'profit4',
        imgUrl: Profit4,
    }
];

const BrokerList = (home, brokerList) => {
    return [
        {
            logo: ZFXLogo,
            title: home.zfxTitle,
            subTitle: home.registerSubTitle,
            forms: brokerList[0].values,
            buttonText: home.registerBtnTxt,
            footNote: home.registerFootNotes,
            url: Constants.ZFX_REGISTER_URL
        },
        {
            logo: ForexLogo,
            title: home.forexTitle,
            subTitle: home.registerSubTitle,
            forms: brokerList[1].values,
            buttonText: home.registerBtnTxt,
            footNote: home.registerFootNotes,
            url: Constants.FOREX_REGISTER_URL
        },
        {
            logo: FBSLogo,
            title: home.fbsTitle,
            subTitle: home.registerSubTitle,
            forms: brokerList[2].values,
            buttonText: home.registerBtnTxt,
            footNote: home.registerFootNotes,
            url: Constants.FBS_REGISTER_URL
        }
    ];
}

export { profitList, profitList2, BrokerList };