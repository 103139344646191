import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect, Fragment } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import LanguageHandler from './LanguageHandler';
import useTranslation from '../../../customHooks/translations';

export default function NavHeader() {
    const { header } = useTranslation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setAnchorEl(null);
    }, []);

    const onClickHome = () => navigate("/");
    const onClickBroker = () => navigate("/broker");
    const onClickPackage = () => navigate("/package");
    const onClickBrokerRegister = () => {
        handleClose();
        navigate("/broker");
    };

    const navStyles = () => ({ minWidth: 80, color: 'white', cursor: 'pointer' });
    return (
        <Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'end' }}>
                <Typography sx={navStyles} onClick={onClickHome}>Home</Typography>
                <Typography sx={navStyles} onClick={onClickBroker}>Broker</Typography>
                <Typography sx={navStyles} onClick={handleClick}>Guide</Typography>
                <Typography sx={navStyles} onClick={onClickPackage}>Package</Typography>
                <Typography sx={navStyles} onClick={() => navigate("/platforms")}>Platforms</Typography>
                <Menu
                    MenuListProps={{
                        'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    id="guide-menu"
                    onClick={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                left: 44,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    }}
                >
                    <MenuItem onClick={onClickPackage}>{header.buyPackage}</MenuItem>
                    <MenuItem onClick={onClickBrokerRegister}>{header.registerBroker}</MenuItem>
                </Menu>
                <LanguageHandler />
            </Box>
        </Fragment >
    );
}
