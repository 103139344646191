import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import HomeTemplates from '../../templates/Home';
import useTranslation from '../../customHooks/translations';

import { profitList, profitList2, BrokerList } from "./element";

export default function HomePage() {
    const { home } = useTranslation();
    const navigate = useNavigate();

    const registerRef = useRef();
    const packageRef = useRef();
    const profitRef = useRef();

    const brokerList = BrokerList(home, home.registerFroms);

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    const onClickPackage = (item) => {
        const packages = home.packages;
        for (var i = 0; i < packages.length; i++) {
            packages[i] = getPackagePrice(packages[i]);
            packages[i].label = `${packages[i].name} - $${packages[i].price}`;
            packages[i].value = packages[i].id;
        }
        const data = {
            selected: getPackagePrice(item),
            packageList: packages
        };
        navigate("/payment", { state: data })
    };

    const getPackagePrice = (item) => {
        const minDepositSplit = item.minDepositTxt.split("$");
        if (minDepositSplit.length > 1) {
            item['minDeposit'] = parseInt(minDepositSplit[1]);
        }

        if (item.maxDepositTxt) {
            const maxDepositSplit = item.maxDepositTxt.split("$");
            if (maxDepositSplit.length > 1) {
                item['maxDeposit'] = parseInt(maxDepositSplit[1]);
            }
        }

        item["id"] = item.name.toLowerCase().replace(" ", "_");

        return item;
    };

    return (
        <HomeTemplates
            data={home}
            brokerList={brokerList}
            packageCards={home.packages}
            profitList={profitList}
            profitList2={profitList2}
            affiliate={home.affiliate}
            onClickPackage={onClickPackage}
            registerRef={registerRef}
            packageRef={packageRef}
            profitRef={profitRef}
        />
    );
}