import { createSelector } from 'reselect';
import { initialState } from './generalReducer';

const selectGeneralReducer = (state) => state.generalReducer || initialState;

const makeSelectLoading = () => createSelector(
    selectGeneralReducer,
    (generalState) => generalState.isLoading,
);

export { makeSelectLoading };