import { PAYMENT_PROCESS, PAYMENT_PROCESS_RESULT } from "../../constants";

export function paymentProcess(postBody) {
    return {
        type: PAYMENT_PROCESS,
        postBody
    };
}

export function paymentResult(data) {
    return {
        type: PAYMENT_PROCESS_RESULT,
        result: data
    };
}