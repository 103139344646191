import styled from "styled-components";

const Container = styled.div`
    display: flex;
    padding: 20px;
    padding-bottom: 10px;
    position: fixed;
    width: 100%;
    background-color: rgba(0,0,0, 0.8);
    z-index: 2;
    background: black;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${(window.innerWidth/20)}%;
`;

const LeftContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Label = styled.label`
    margin-left: 10px;
    color: white;
    width: 270px
`;

const Image = styled.img`
    width: 50px
`;

export { Container, Wrapper, LeftContainer, Label, Image };