import { NO_INTERNET_CONNECTION } from "../../components/Constants";

function isFilledArray(items) {
    return Array.isArray(items) && items?.length > 0;
}

function queryParamStringify(params) {
    let result = '';
    const paramKeys = Object.keys(params ?? {});
    if (isFilledArray(paramKeys)) {
        const transformParams = paramKeys.map((key) => `${key}=${params[key]}`);
        result = `?${transformParams.join('&')}`;
    }
    return result;
}

function header(options) {
    const customHeadersKeys = options?.headers ? Object.keys(options.headers) : [];
    const contentType = 'application/json';

    if (isFilledArray(customHeadersKeys)) {
        return {
            'Content-Type': contentType,
            ...options.headers,
        };
    }

    return {};
}

function bodyCollector(options) {
    if (options?.requestType === 'formData' && options?.request) {
        var reqBody = new FormData();
        for (const [key, value] of Object.entries(options?.request)) {
            reqBody.append(key, value);
        }

        return reqBody;
    }

    if (!options?.requestType) {
        return JSON.stringify(options?.request);
    }

    return options?.request;
}

async function request(method, endpoint, options) {
    try {
        const baseUrl = options?.baseUrl ?? "";
        const headers = header(options);
        const requestBody = bodyCollector(options);
        const requestURL = `${baseUrl}${endpoint}${queryParamStringify(options?.params)}`;
        const fetchOptions = {
            method,
            headers,
            body: method !== 'GET' ? requestBody : null,
            timeout: options?.timeout || 20000
        };

        let result = {};
        const response = await fetch(requestURL, fetchOptions);
        if (response.status >= 200 && response.status < 300) {
            switch (response.status) {
                case 208:
                    result.statusCode = response.status;
                    result.responseResult = null;
                    throw result;
                case 204:
                    result = null;
                    break;
                case 205:
                    result = null;
                    break;
                default:
                    result = await response.json();
                    break;
            }
            return result;
        }

        result.statusCode = response.status;
        result.responseResult = response.status !== 404 ? await response.json() : null;
        throw result;
    } catch (err) {
        const errorResult = { responseResult: null, statusCode: 0 };
        if (!err?.statusCode) {
            errorResult.responseResult = {
                succeeded: false,
                message: NO_INTERNET_CONNECTION,
                data: null,
            };
        } else {
            errorResult.statusCode = err?.statusCode;
            errorResult.responseResult = err?.responseResult;
        }
        throw errorResult;
    }
}

async function GET(endpoint, options) {
    return request('GET', endpoint, options);
}

async function POST(endpoint, options) {
    const result = await request('POST', endpoint, options);
    return result;
}

export default { GET, POST };