import {
    put,
    takeLatest,
} from 'redux-saga/effects';
import { PAYMENT_URL } from '../../../utils/services/api';
import Request from '../../../utils/services/request';

import { PAYMENT_PROCESS } from '../../constants';
import { isLoading } from '../generalAction';
import { paymentResult } from './action';

export function* doPayment({ postBody }) {
    try {
        yield put(isLoading(true));
        const result = yield Request.POST(PAYMENT_URL, { request: postBody });
        yield put(paymentResult(result));
    } catch (error) {
        yield put(isLoading(false));
    } finally {
        yield put(isLoading(false));
    }
}

export default function* paymentProcess() {
    yield takeLatest(PAYMENT_PROCESS, doPayment);
}