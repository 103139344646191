import styled from "styled-components";

const Container = styled.div`
    width: 20%;
    background: white;
    margin: auto;
    border-radius: 8px;
    text-align: center;
    padding: 15px;
`;

const Title = styled.p`
    font-size: 20px;
`;

export { Container, Title };