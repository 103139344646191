import { combineReducers } from 'redux';
import generalReducer from './feature/generalReducer';
import registerReducer from "./feature/register/reducer";
import paymentReducer from "./feature/payment/reducer";

const globalReducer = combineReducers({
  generalReducer,
  registerReducer,
  paymentReducer
});

const rootReducer = (state, action) => {
    return globalReducer(state, action);
  };
  
  export default rootReducer;