import styled from "styled-components";

const Card = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    transition: 0.5s;
    padding: 0;
    margin-right: ${props => props.marginRight ?? "50px"};
    text-align: center;
    cursor: pointer;
    & {
        background: linear-gradient(45deg, red, lightgreen );
    }
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(45deg, green, lightgreen);
        filter: blur(30px);
    }
`;

const Wrapper = styled.span`
    content: '';
    position: absolute;
    top: 6px;
    bottom: 6px;
    left: 6px;
    right: 6px;
    background: rgba(0,0,0,0.6);
`;

const CardWrapper = styled.div`
    z-index: 1;
    padding: 20px 20px;
    text-align: left;
    font-size: 16px;
    display: inline-grid;
    cursor: pointer;
`;

const Title = styled.h4`
    color: gold;
`;

const Label = styled.h5`
    color: white;
    text-align: center;
    padding-top: ${props => props.paddingTop ?? "10px"};
    padding-bottom: ${props => props.paddingBottom ?? "0px"};
    margin-bottom: 30px;
`;

export { Card, Wrapper, CardWrapper, Title, Label };