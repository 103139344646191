import styled from 'styled-components';
import CheckboxUI from '@mui/material/Checkbox';

const Container = styled.div`
    display: flex;
    align-items: center;
    margin: ${({ margin }) => margin ?? "auto"}
`;

const Label = styled.label`
    font-size: 13px;
    text-align: left;
`;

export { CheckboxUI, Container, Label };