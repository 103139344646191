import produce from 'immer';
import { IS_LOADING } from "../constants";

export const initialState = {
    isLoading: false
};

const generalReducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case IS_LOADING:
            draft.isLoading = action.isLoading;
            break;
        default:
            break;
    }
});

export default generalReducer;