import React from "react";
import { CheckboxUI, Container, Label } from "./styles";

export default function CheckBox(props) {
    const { label, value, required, margin, onChange } = props;
    return (
        <Container margin={margin}>
            <CheckboxUI
                checked={value}
                required={required}
                onChange={(e) => onChange(e.target.checked)}
            />
            <Label>{label}</Label>
        </Container>
    );
}

CheckBox.defaultProps = {
    label: "",
    value: false,
    required: false,
    margin: "auto",
    onChange: () => { }
}