import React, { useEffect } from "react";
import useTranslation from "../../customHooks/translations";
import RegisterCard from "../../ui/organisms/RegisterCard";
import { BrokerList } from "../Home/element";
import { Container } from "./styles";

export default function Broker() {
    const { home, broker } = useTranslation();
    const brokerList = BrokerList(home, broker.brokerList);
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <Container>
            {brokerList.length > 0 && brokerList.map((item, index) => {
                var margin = index === brokerList.length - 1 ? "0" : "0 0 70px 0";
                return (<RegisterCard key={index.toString()} data={item} margin={margin} />);
            })}
        </Container>
    );
}