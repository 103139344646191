import React from "react";
import TextField from "../../ui/atoms/TextField";
import Button from "../../ui/atoms/Button";
import {
    Container, Title, Wrapper,
    FormContainer, ButtonContainer, Label, BodyContainer
} from '../Register/styles';
import Constants from "../../Constants";
import ReactHtmlParser from 'react-html-parser';
import CheckBox from "../../ui/atoms/CheckBox";
import { RowWrapper, RateLabel } from './styles';

export default function PaymentTemplate(props) {
    const {
        data, onClick, onChange, submitDisabled, amountEl,
        aggreementEl, checkboxHandler, rateText
    } = props;
    const { title, submitBtnTxt } = data;

    const renderUI = (element, index) => {
        var ui = null;
        const id = `${index}`;

        switch (element.type) {
            case Constants.INPUT_TYPE:
            case Constants.INPUT_ANDORMENTS:
            case Constants.SELECT_TYPE:
                ui = <TextField
                    data={element}
                    id={id}
                    onChange={(value) => onChange(index, value, element.id)}
                />
                break;
            case Constants.LABEL_TYPE:
                ui = <Label
                    width={element.width}
                    height={element.height}
                    background={element.background}
                    margin={element.margin}
                    borderRadius={element.borderRadius}
                    textAlign={element.textalign}
                    padding={element.padding}
                    fontSize={element.fontSize}
                >
                    {ReactHtmlParser(element.label)}
                </Label>
                break;
            case Constants.CHECKBOX_TYPE:
                ui = <CheckBox
                    label={element.label}
                    value={element.value}
                    required={element.required}
                    margin={element.margin}
                    onChange={(value) => checkboxHandler(value)}
                />
                break;
            default:
                break;
        }

        return ui;
    };
    return (
        <Container autoComplete="off">
            <Title>{title.toUpperCase()}</Title>
            <BodyContainer width="43%">
                <FormContainer>
                    {data.element.map((element, index) => {
                        if (!element.isDisplay) {
                            return null;
                        }
                        return (
                            <Wrapper
                                key={index.toString()}
                                marginBottom="30px"
                                height={element.height}
                            >
                                {renderUI(element, index)}
                            </Wrapper>
                        );
                    })}
                    <RowWrapper>
                        {amountEl.map((element, index) => {
                            if (!element.isDisplay) {
                                return null;
                            }
                            return (
                                <Wrapper
                                    key={index.toString()}
                                    marginBottom="30px"
                                    height={element.height}
                                >
                                    {renderUI(element, index)}
                                </Wrapper>
                            );
                        })}
                    </RowWrapper>
                    <RateLabel>{rateText}</RateLabel>
                    <Wrapper
                        marginBottom="30px"
                        height={aggreementEl.height}
                    >
                        {renderUI(aggreementEl, 0)}
                    </Wrapper>
                </FormContainer>
                <ButtonContainer>
                    <Button
                        text={submitBtnTxt}
                        width="200px"
                        onClick={onClick}
                        disabled={submitDisabled}
                    />
                </ButtonContainer>
            </BodyContainer>
        </Container>
    );
}

PaymentTemplate.defaultProps = {
    data: {},
    amountEl: [],
    aggreementEl: {},
    submitBtnTxt: "",
    rateText: "",
    submitDisabled: false,
    onClick: () => { },
    onChange: () => { },
    checkboxHandler: () => { }
};