module.exports = {
    INPUT_TYPE: "InputField",
    INPUT_ANDORMENTS: "InputAndorments",
    SELECT_TYPE: "Select",
    LABEL_TYPE: "LabelType",
    CHECKBOX_TYPE: "CheckBoxType",
    ZFX_REGISTER_URL: "https://zfx.world/en/reg/live",
    FOREX_REGISTER_URL: "https://justforex-idn.com",
    FBS_REGISTER_URL: "https://fbsbroker-id.com",
    BINARY_TREE_URL: "http://app.naf1.co.id/daftar/NJ1I3IKZ",
    RP_USD: 14369.70,
    BACKGROUND_COLOR: "#1d1d1d",
    NO_INTERNET_CONNECTION: "No Internet Connection"
};