import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGeneralReducer = (state) => state.registerReducer || initialState;

const makeSelectRegisterResult = () => createSelector(
    selectGeneralReducer,
    (generalState) => generalState.registerResult,
);

export { makeSelectRegisterResult };