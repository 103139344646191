import React from "react";
import {
    Container, Title, Image,
    FormContainer, Wrapper, ButtonContainer, BodyContainer
} from "./styles";
import Button from "../../ui/atoms/Button";
import TextField from "../../ui/atoms/TextField";
import CheckBox from "../../ui/atoms/CheckBox";

export default function RegisterTemplate(props) {
    const {
        title, forms, onChange, image,
        submitBtnTxt, cancelBtnTxt, disabledSubmit,
        onClickSubmit, onClickCancel, aggrement
    } = props;
    
    return (
        <Container autoComplete="off">
            <Title>{title.toUpperCase()}</Title>
            <Image src={image} />
            <BodyContainer width="50%">
                <FormContainer display="flex">
                    {forms.map((item, row) => {
                        return (
                            <Wrapper key={row.toString()}>
                                {item.map((child, col) => {
                                    const id = `${row}-${col}`;
                                    const position = { row, col };
                                    return (
                                        <Wrapper
                                            key={col.toString()}
                                            marginBottom="30px"
                                            height={child.height}
                                        >
                                            <TextField
                                                data={child}
                                                id={id}
                                                onChange={(value) => onChange(position, value)}
                                            />
                                        </Wrapper>
                                    );
                                })}
                            </Wrapper>
                        );
                    })}
                </FormContainer>
                <CheckBox
                    label={aggrement.label}
                    value={aggrement.value}
                    required={aggrement.required}
                    margin={aggrement.margin}
                    onChange={(value) => onChange(-1, value)}
                />
                <ButtonContainer>
                    <Button
                        text={submitBtnTxt}
                        width="200px"
                        disabled={disabledSubmit}
                        onClick={onClickSubmit}
                    />
                    <Button
                        text={cancelBtnTxt}
                        width="200px"
                        type="outlined"
                        onClick={onClickCancel}
                    />
                </ButtonContainer>
            </BodyContainer>
        </Container>
    );
}

RegisterTemplate.defaultProps = {
    forms: [],
    aggrement: {},
    title: "",
    image: "",
    submitBtnTxt: "",
    cancelBtnTxt: "",
    disabledSubmit: true,
    onClickSubmit: () => { },
    onClickCancel: () => { },
    onChange: () => { }
};