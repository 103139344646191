import styled from "styled-components";
import MButton from "@mui/material/Button";

const Container = styled.div`
    margin: ${({ margin }) => margin ?? "auto"};
`;
const Image = styled.img`
    width: 290px;
    height: 160px;
    cursor: pointer;
`;
const Button = styled(MButton)`
    margin-top: 100px
`;
const Title = styled.p`
    color: white;
    padding-top: 10px;
    margin-bottom: 0;
`;

const Label = styled.label`
    color: white;
    padding-top: 10px;
`;
const FormWrapper = styled.div`
    display: flex;
    gap: 7px;
`;
const Divider = styled.div`
    height: 1px;
    background: lightgray;
`;
const Rounded = styled.div`
    border-radius: 50%;
    width: 13px;
    height: 13px;
    margin-top: 16px;
    border: 2px solid #00a0dd;
`;
const OptionsContainer = styled.div`
    margin-left: 22px;
`;

export {
    Container, Image, Button, Title, Label, FormWrapper,
    Divider, Rounded, OptionsContainer
};