import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Register from "./pages/Register";
import PaymentPage from "./pages/Payment";
import Platforms from "./pages/Platforms";
import Broker from "./pages/Broker";
import Packages from "./pages/Packages";

export default function NafRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/payment" element={<PaymentPage />} />
            <Route exact path="/package" element={<Packages />} />
            <Route exact path="/registration" element={<Register />} />
            <Route exact path="/broker" element={<Broker />} />
            <Route exact path="/platforms" element={<Platforms />} />
            <Route path="*" element={<Home />} />
        </Routes>
    );
}
