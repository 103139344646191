import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px);
    background-color: ${({ backgroundColor }) => backgroundColor};
    z-index: ${({ isIndex }) => (isIndex ? '100' : '99')};
    position: fixed;
    top: 0px;
    display: flex;    
    justify-content: center;
    align-items: center;
`;

export default Container;
