import {
    put,
    takeLatest,
} from 'redux-saga/effects';
import { REGISTER_URL } from '../../../utils/services/api';
import Request from '../../../utils/services/request';

import { REGISTER_PROCESS } from '../../constants';
import { isLoading } from '../generalAction';
import { registerResult } from './action';

export function* doRegister({ postBody }) {
    try {
        yield put(isLoading(true));
        const result = yield Request.POST(REGISTER_URL, { request: postBody });
        yield put(registerResult(result));
    } catch (error) {
        yield put(isLoading(false));
    } finally {
        yield put(isLoading(false));
    }
}

export default function* registerProcess() {
    yield takeLatest(REGISTER_PROCESS, doRegister);
}