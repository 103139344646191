const language = {
  requiredErrorTxt: 'The field is required',
  okButtonTxt: "OK",
  header: {
    title: 'EA Atomic Arista - Traders Community',
    buyPackage: 'Beli Paket',
    registerBroker: "Registrasi Broker",
    registerNaf1: "Registrasi NAF1",
    binaryTreeTxt: "Binary Tree"
  },
  home: {
    title: "SELAMAT DATANG DI EA Atomic Arista - Traders Community",
    subTitle: "Menjadi seorang trader adalah profesi yang menjanjikan di masa pandemi dan di masa depan. Dimana kami telah menyediakan EA yang mudah dan menguntungkan untuk Anda para trader hebat. Dimana untuk hari ini untuk memulai menjadi seorang trader sangatlah mudah tanpa kita harus selalu memantau pergerakan pasar. kita hanya membutuhkan bantuan sistem otomatis atau robot yang sering disebut Expert Advisors (EA) untuk memulai trading.",
    zfxTitle: "REGISTRASI AKUN ZFX",
    forexTitle: "REGISTRASI AKUN JUSTFOREX",
    fbsTitle: "REGISTRASI AKUN FBS",
    registerSubTitle: "Silahkan daftar melalui link dibawah ini. Setelah memasuki halaman, pastikan Anda mengisi data pribadi Anda dengan lengkap:",
    registerFroms: [
      {
        name: "ZFX",
        values: [
          {
            key: "Nama : ",
            value: "Sesuai KT/Passport"
          },
          {
            key: "Nomor Telepon : ",
            value: "Nomor Aktif",
          },
          {
            key: "Email : ",
            value: "Email Aktif",
          }
        ]
      },
      {
        name: "JUST FOREX",
        values: [
          {
            key: "Nama : ",
            value: "Sesuai KT/Passport"
          },
          {
            key: "Nomor Telepon : ",
            value: "Nomor Aktif",
          },
          {
            key: "Email : ",
            value: "Email Aktif",
          }
        ]
      },
      {
        name: "FBS",
        values: [
          {
            key: "Nama : ",
            value: "Sesuai KT/Passport"
          },
          {
            key: "Nomor Telepon : ",
            value: "Nomor Aktif",
          },
          {
            key: "Email : ",
            value: "Email Aktif",
          }
        ]
      }
    ],
    registerFootNotes: "*Setelah menyelesaikan pendaftaran, sistem akan mengirimkan kata sandi login dan broker Transaksi ke email register",
    registerBtnTxt: "Daftar",
    packages: [
      {
        name: "EA XAU Atomic",
        priceTxt: "Free EA bot $2100",
        price: 15000,
        minDepositTxt: "Min Deposit $15000",
        maxDepositTxt: "Max Deposit $90000",
        startFromTxt: "Start from $15000",
        buttonTxt: "EA XAU Atomic",
        description: `XAU Atomic EA diperkenalkan sebagai “Strategi Trading Otomatis Paling Kuat”. Hal ini dapat bekerja dengan tujuh pasang. Sistem menghasilkan keuntungan dengan melakukan order berdasarkan kekuatan dan kelemahan YEN, USD, GBP, EURO, dan Emas.`
      },
      {
        name: "EA Correlation Atomic",
        priceTxt: "Free EA bot $700",
        price: 10000,
        minDepositTxt: "Min Deposit $10000",
        maxDepositTxt: "Max Deposit $15000",
        startFromTxt: "Start from $10000",
        buttonTxt: "EA Correlation",
        description:`Setiap mata uang memiliki korelasi langsung atau tidak langsung di antara mereka yang bisa positif atau negatif tergantung pada mata uang yang dapat diukur dari -100% hingga 100%. Di mana -100% berarti mata uang bergerak persis berlawanan satu sama lain sedangkan 100% berarti mata uang bergerak ke arah yang sama dan di kisaran tengah mereka berkorelasi sesuai persen korelasi di antara mereka.
        Korelasi antara pasangan ini terus berubah jangkauannya yang dapat bervariasi dari negatif ke positif & positif ke negatif.
        Sekarang tergantung pada jenis korelasi antara dua pasangan, EA Korelasi kami akan menempatkan perdagangan sebagai BELI-JUAL atau JUAL-BUY
        dan menunggu keuntungan kolektifnya dan setelah ambang batas tercapai, EA menutup kedua perdagangan`
      },
      {
        name: "EA XAU Manifest",
        priceTxt: "Free EA bot $350",
        price: 5000,
        minDepositTxt: "Min Deposit $5000",
        maxDepositTxt: "Max Deposit $10000",
        startFromTxt: "Start from $5000",
        buttonTxt: "EA XAU Manifest",
        description: `adalah Expert Advisor yang dirancang khusus untuk trading Emas H1.
        Ini menggunakan beberapa indikator untuk menemukan Entri yang baik. Dan Anda dapat mengatur SL atau Anda dapat DCA jika Anda mau.
        Ini bisa berupa Scalping atau Grid/ Martingale tergantung pengaturan Anda.
        EA ini dapat Auto lot by Balance, mengatur risiko per perdagangan. Anda juga dapat mengatur TP/SL untuk setiap perdagangan atau untuk keranjang perdagangan.`
      },
      {
        name: "EA Balmonic Semi Auto",
        priceTxt: "Free EA bot $350",
        price: 3000,
        minDepositTxt: "Min Deposit $3000",
        maxDepositTxt: "Max Deposit $5000",
        startFromTxt: "Start from $3000",
        buttonTxt: "EA Balmonic",
        description: `indikator yang digunakan MACD, EMA, dan amplop semua OP didasarkan pada tren besar dilihat dari MACD dan EMA`
      }
    ],
    affiliate: {
      title: "Sistem Affiliasi",
      description: "Sistem yang digunakan untuk pemasaran NAF1Team adalah sistem pemasaran berjenjang dengan konsep AFFILIATE, dimana seorang direct seller dapat memiliki direct CLIENT sebanyak yang mereka butuhkan.",
      list: [
        "Afiliasi Level 1 mendapatkan 10%",
        "Afiliasi Level 2 mendapatkan 5%",
        "Afiliasi Level 3 mendapatkan 3%",
        "Afiliasi Level 4 mendapatkan 2%"
      ]
    }
  },
  register: {
    title: "Pendaftaran",
    firstName: "Nama Depan",
    lastName: "Nama Belakang",
    email: "Email",
    phone: "Telepon",
    referall: "Kode Referal",
    amount: "Deposit",
    tradingTxt: "Trading",
    tradingPasswordTxt: "Password Trading dari Email",
    investorPasswordTxt: "Password Investor dari Email",
    serverTxt: "Server",
    brokerTxt: "Broker",
    passwordPortalTxt: "Password Portal Broker",
    submitBtnTxt: "Kirim",
    cancelBtnTxt: "Batal",
    registerSuccessTxt: "Registrasi telah berhasil dikirim",
    aggrementTxt: "Saya setuju mengirim informasi ini untuk diserahkan kepada Team NAF1 untuk dilakukan konfigurasi bot di server trading."
  },
  payment: {
    title: "Pembayaran",
    submitBtnTxt: "Bayar",
    aggrementTxt: "I agree to pay amount $amount to buy software.",
    paymentSuccessTxt: "Pembayaran telah berhasil dikirim",
    forms: {
      packageNameTxt: "Nama Paket",
      priceTxt: "Price",
      paymentMethodTxt: "Payment Method",
      usdtTxt: "USDT",
      localBankTxt: "Local Bank",
      usdtDescTxt: "Tether (USDT) Cryptocurrency Payment<br/>Open your cryptocurrency wallet, enter (ADDRESS Wallet <b>$id</b>) and transfer $amount USDT.<br/>Your fund transfer may take from within an hour up to 1 business day.",
      depositTxt: "Total Deposit",
      totalTransferTxt: "Total Transfer",
      linkTransferTxt: "Link Bukti Pembayaran",
      fullNameTxt: "Nama",
      emailTxt: "Email",
      phoneNumberTxt: "Phone",
      buyerCountryTxt: "Buyer Country",
      bankAccountTxt: "Rekening Bank",
      linkTransferDescTxt: "*Upload your transfer receipt <a href='https://imgbb.com' target='_blank'>here</a> and copy paste the link into link payment receipt field."
    }
  },
  broker: {
    brokerList: [
      {
        name: "ZFX",
        values: [
          {
            key: "",
            value: "Register using your detail profile"
          },
          {
            key: "",
            value: "Login in MY ZFX"
          },
          {
            key: "",
            value: "Click Trading Account",
          },
          {
            key: "",
            value: "Choose New Account",
          },
          {
            key: "",
            value: "Choose MT4",
          },
          {
            key: "",
            value: "Choose Standar Cent",
          },
          {
            key: "",
            value: "Choose Open Real Account",
          },
          {
            key: "",
            value: "Currency USD",
          },
          {
            key: "",
            value: "Leverage 1:2000",
          },
          {
            key: "",
            value: "Setting Trading Password",
          },
          {
            key: "",
            value: "Choose Create Account",
          },
          {
            key: "",
            value: "Input to Form Online",
            options: [
              {
                key: "",
                value: "Login MY ZFX",
                options: [
                  {
                    key: "",
                    value: "Email",
                  },
                  {
                    key: "",
                    value: "Password",
                  }
                ]
              },
              {
                key: "",
                value: "Trading Account MT4 Cent",
                options: [
                  {
                    key: "",
                    value: "Server - ZealCapitalMarketSC-Live",
                  },
                  {
                    key: "",
                    value: "User Id",
                  },
                  {
                    key: "",
                    value: "Password Trader",
                  },
                  {
                    key: "",
                    value: "Password Investor",
                  }
                ]
              }
            ]
          },
          {
            key: "",
            value: "Verify Account with ID Card and Bank Info Detail",
          },
          {
            key: "",
            value: "Deposit on ZFX",
          }
        ]
      },
      {
        name: "JUST FOREX",
        values: [
          {
            key: "",
            value: "Register using your detail profile"
          },
          {
            key: "",
            value: "Click my account"
          },
          {
            key: "",
            value: "Choose new account"
          },
          {
            key: "",
            value: "Choose MT4"
          },
          {
            key: "",
            value: "Choose standar cent"
          },
          {
            key: "",
            value: "Choose open real account"
          },
          {
            key: "",
            value: "Currency USC"
          },
          {
            key: "",
            value: "Leverage 1:1000"
          },
          {
            key: "",
            value: "Setting Trading Password"
          },
          {
            key: "",
            value: "Choose Create Account"
          },
          {
            key: "",
            value: "Input to Form Online",
            options: [
              {
                key: "",
                value: "Login Just Forex",
                options: [
                  {
                    key: "",
                    value: "Email"
                  },
                  {
                    key: "",
                    value: "Password"
                  }
                ]
              }
            ]
          },
          {
            key: "",
            value: "Trading Account MT4 Cent",
            options: [
              {
                key: "",
                value: "Server - JustForex-Live"
              },
              {
                key: "",
                value: "User Id"
              },
              {
                key: "",
                value: "trader Password"
              },
              {
                key: "",
                value: "Investor Password"
              },
            ]
          },
          {
            key: "",
            value: "Verify Account with ID Card and Bank Info Detail"
          },
          {
            key: "",
            value: "Deposit on Just Forex"
          },
        ]
      },
      {
        name: "FBS",
        values: [
          {
            key: "",
            value: "Choose Create Account"
          },
          {
            key: "",
            value: "Choose New Account"
          },
          {
            key: "",
            value: "Create Cent Account"
          },
          {
            key: "",
            value: "Don't Activate Fixrate"
          },
          {
            key: "",
            value: "Leverage 1:1000"
          },
          {
            key: "",
            value: "Verify ID and Email"
          },
          {
            key: "",
            value: "Depoit",
            oprions: [
              {
                key: "",
                value: "Login FBS",
                options: [
                  {
                    key: "",
                    value: "Email"
                  },
                  {
                    key: "",
                    value: "Password"
                  },
                ]
              },
            ]
          },
          {
            key: "",
            value: "Trading Account MT4 Cent",
            options: [
              {
                key: "",
                value: "Server"
              },
              {
                key: "",
                value: "User Id"
              },
              {
                key: "",
                value: "Trader Password"
              },
              {
                key: "",
                value: "Investor Password"
              },
            ]
          },
        ]
      }
    ]
  },
  platforms: {
    metatrader4Txt: "METATRADER 4",
    forPCTxt: "UNTUK WINDOWS",
    forAndroidTxt: "UNTUK ANDROID",
    forIOSTxt: "UNTUK IOS / IPHONE",
    forMacTxt: "UNTUK MAC",
    forNaf1AndroidTxt: "UNTUK NAF1 ANDROID",
    forNaf1IOSTxtx: "UNTUK NAF1 IOS",
    downloadTxt: "UNDUH"
  },
  profit: {
    title: "Profit",
    disclamerTxt: "Profit dapat berubah sewaktu-waktu, tergantung kondisi pasar."
  }
};

export default language;
