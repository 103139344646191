import styled from "styled-components";

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row ;
  height:${({ height }) => height ?? "auto"};
  margin-right: 56px;
  margin-left: 61px ;
`;

const RateLabel = styled.p`
  margin-left: 62px;
  margin-top: -25px;
  font-size: 12px;
  text-align: left ;
`;

export { RowWrapper, RateLabel };