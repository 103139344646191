import styled from "styled-components";
import Constants from "../../Constants";

const Container = styled.div`
    padding: 10px;
    width: 100%;
`;

const HeaderContainer = styled.div`
    width: 90%;
    margin: auto;
`;

const Wrapper = styled.div`
    margin-top: ${({ marginTop }) => marginTop ?? "auto"};
`;

const Title = styled.h1`
    color: gold;
    font-weight: bold;
    text-align: center;
    font-size: 22px
`;

const SubTitle = styled.div`
    color: ${({ color }) => color ?? "white"};;
    font-size: 18px;
    padding: ${({ padding }) => padding ?? "20px"};
    text-align: justify;
    margin-top: ${({ marginTop }) => marginTop ?? "20px"};
`;

const RegisterCardContainer = styled.div`
    display: flex;
    margin-top: 60px;
    gap: 30px;
    align-items: baseline;
    background: ${Constants.BACKGROUND_COLOR};
    border-radius: 8px;
    padding: 15px;
`;

const PackageCardContainer = styled.div`
    display: flex;
    margin-top: 60px;
    margin-left: 8rem;
`;

const ProfitContainer = styled.div`
    margin-top: 60px;
    text-align: -webkit-center;
    text-align: -moz-center;
`;

const AffiliateContainer = styled.div`
    width: 100%;
    margin:auto;
    margin-top: 60px;
`;

const AffiliatListContainer = styled.div`
    margin-left: 20px;
    margin-top: 30px;
`;

const Label = styled.p`
    color: white
`;

const Rounded = styled.div`
    border-radius: 50%;
    width: 13px;
    height: 13px;
    margin-top: 7px;
    border: 2px solid #00a0dd;
`;

export {
    Container, Title, SubTitle, RegisterCardContainer,
    PackageCardContainer, ProfitContainer, AffiliateContainer,
    Label, Rounded, AffiliatListContainer, HeaderContainer, Wrapper
};