import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../customHooks/translations";
import PackageCard from "../../ui/organisms/PackageCard";
import { Container } from "./styles";

export default function Packages() {
    const { home } = useTranslation();
    const { packages: packageCards } = home;
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    const onClickPackage = (item) => {
        for (var i = 0; i < packageCards.length; i++) {
            packageCards[i] = getPackagePrice(packageCards[i]);
            packageCards[i].label = `${packageCards[i].name} - $${packageCards[i].price}`;
            packageCards[i].value = packageCards[i].id;
        }
        const data = {
            selected: getPackagePrice(item),
            packageList: packageCards
        };
        navigate("/payment", { state: data })
    };

    const getPackagePrice = (item) => {
        const minDepositSplit = item.minDepositTxt.split("$");
        if (minDepositSplit.length > 1) {
            item['minDeposit'] = parseInt(minDepositSplit[1]);
        }

        if (item.maxDepositTxt) {
            const maxDepositSplit = item.maxDepositTxt.split("$");
            if (maxDepositSplit.length > 1) {
                item['maxDeposit'] = parseInt(maxDepositSplit[1]);
            }
        }

        item["id"] = item.name.toLowerCase().replace(" ", "_");

        return item;
    };

    return (
        <Container>
            {packageCards.length > 0 && packageCards.map((item, index) =>
                <PackageCard
                    key={index.toString()}
                    data={item}
                    isLast={index === packageCards.length - 1}
                    onClick={() => onClickPackage(item)}
                />
            )}
        </Container>
    );
}