import React from 'react';
import { Input } from './styles';
import MenuItem from '@mui/material/MenuItem';

export default function InputSelect(props) {
    const {
        id, label, value, onChange,
        helperText, error, options, required,
        height, width, disabled, textalign
    } = props;
    return (
        <Input
            select
            id={id}
            label={label}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            helperText={helperText}
            error={error}
            required={required}
            height={height}
            width={width}
            textalign={textalign}
            disabled={disabled}
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Input>
    );
}

InputSelect.defaultProps = {
    id: "",
    label: "",
    value: "",
    onChange: () => { },
    helperText: "",
    error: false,
    required: false,
    options: [],
    height: "auto",
    width: "auto",
    disabled: false,
    textalign: "center"
};