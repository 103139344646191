import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGeneralReducer = (state) => state.paymentReducer || initialState;

const makeSelectPaymentResult = () => createSelector(
    selectGeneralReducer,
    (generalState) => generalState.paymentResult,
);

export { makeSelectPaymentResult };