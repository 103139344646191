import en from "./en";
import it from "./it";
import fr from "./fr";
import de from "./de";
import id from "./id";
import ar from "./ar";
import tr from "./tr";
import zh from "./zh";
import ja from "./ja";

export default {
  en,
  it,
  fr,
  de,
  id,
  ar,
  tr,
  zh,
  ja
};
