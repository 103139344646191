import React from "react";
import { Container, PlatformContainer, Wrapper, Title, SubTitle, Image, ButtonContainer } from "./styles";
import Button from "../../ui/atoms/Button"

export default function PlatformsTemplate(props) {
    const { data } = props;
    return (
        <Container>
            {
                data.map((item, index) => {
                    return (
                        <PlatformContainer key={index.toString()}>
                            {
                                item.map((child, childIndex) => {
                                    return (
                                        <Wrapper key={childIndex.toString()}>
                                            <Title>{child.title.toUpperCase()}</Title>
                                            <SubTitle>{child.subTitle.toUpperCase()}</SubTitle>
                                            <Image src={child.image} />
                                            <ButtonContainer>
                                                <Button onClick={() => child.link && window.open(child.link)} text={child.buttonTxt.toUpperCase()} width={"100%"} />
                                            </ButtonContainer>
                                        </Wrapper>
                                    );
                                })
                            }
                        </PlatformContainer>
                    );
                })
            }
        </Container>
    );
}

PlatformsTemplate.defaultProps = {
    data: []
}