import styled from "styled-components";

const Container = styled.div`
    justify-content: center;
`;
const PlatformContainer = styled.div`
    display: flex;
    gap: 30px;
    justify-content: center;
    margin-top: 20px;
`;
const Wrapper = styled.div`
    width: 23%;
    background: #1d1d1d;
    color: white;
    padding: 25px;
    text-align: center;
    border-radius: 5px;
`;
const Title = styled.h2``;
const SubTitle = styled.p``;
const Image = styled.img`
    width: 210px;
    height: 210px;
    margin-top: 15px;
`;
const ButtonContainer = styled.div`
    margin-top: 25px;
`;

export { Container, PlatformContainer, Wrapper, Title, SubTitle, Image, ButtonContainer }