import { all } from 'redux-saga/effects';
import registerSaga from "./feature/register/saga";
import paymentSaga from "./feature/payment/saga";

/**
  * combine all saga
  */
export default function* saga() {
  yield all([
    registerSaga(),
    paymentSaga()
  ]);
}