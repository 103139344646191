import React, { useEffect } from "react";
import useTranslation from "../../customHooks/translations";
import { Element } from "./element";
import PlatformsTemplate from "../../templates/Platforms";

export default function Platforms() {
    const { platforms } = useTranslation();
    const element = Element(platforms);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <PlatformsTemplate data={element} />
    );
}