import styled from "styled-components";
import Box from '@mui/material/Box';

const Container = styled(Box)`
    text-align: center;
`;

const BodyContainer = styled.div`
    background: whitesmoke;
    margin: auto;
    width: ${({ width }) => width ?? "auto"};
    padding-top: 21px;
    margin-top: 60px;
    border-radius: 8px;
    padding-bottom: 21px;
`;

const Title = styled.h2`
    color: gold;
    margin-bottom: 20px;
`;
const FormContainer = styled.div`
    display: ${({ display }) => display ?? "block"};
    gap: 20px;
    justify-content: center;
    margin-top: 15px;
    align-items: baseline;
`;
const Image = styled.img``;
const Wrapper = styled.div`
    margin-bottom:${({ marginBottom }) => marginBottom ?? ""};
    height:${({ height }) => height ?? "auto"}
`;
const ButtonContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    justify-content: center;
`;

const Label = styled.p`
    width: ${({ width }) => width ?? "auto"};
    height: ${({ height }) => height ?? "auto"};
    background: ${({ background }) => background ?? "none"};
    margin: ${({ margin }) => margin ?? "auto"};
    border-radius: ${({ borderRadius }) => borderRadius ?? "0"};
    text-align: ${({ textAlign }) => textAlign ?? "justify"};
    padding: ${({ padding }) => padding ?? "0"};
    font-size: ${({ fontSize }) => fontSize ?? "auto"};
`;

const AggrementContainer = styled.div``;

export {
    Container, Title, Image,
    FormContainer, Wrapper, ButtonContainer,
    Label, BodyContainer, AggrementContainer
};