import React from 'react';
import { createPortal } from 'react-dom';

import Container from './styled';

export const modalRootId = 'modal-root';

export default function Modal({ children, backgroundColor, isIndex }) {
    return createPortal(
        <Container backgroundColor={backgroundColor} isIndex={isIndex}>{children}</Container>,
        document.getElementById(modalRootId),
    );
}

Modal.defaultProps = {
    children: <div />,
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    isIndex: false,
};
