import React, { useEffect } from "react";

export default function XAUUSDChart() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://s3.tradingview.com/tv.js";
        script.async = false;

        const scriptCode = document.createElement('script');
        scriptCode.type = "text/javascript";
        scriptCode.text = `
        new TradingView.widget(
                {
                "width": 980,
                "height": 500,
                "symbol": "FOREXCOM:XAUUSD",
                "interval": "D",
                "timezone": "Etc/UTC",
                "theme": "dark",
                "style": "1",
                "locale": "en",
                "toolbar_bg": "#f1f3f6",
                "enable_publishing": false,
                "allow_symbol_change": true,
                "container_id": "tradingview"
            }
        );
        `
        script.onload = () => {
            document.body.appendChild(scriptCode);
        };
        document.body.appendChild(script);

        return () => {
            document.body?.removeChild(script);
            document.body?.removeChild(scriptCode);
        }
    }, []);

    return (
        <div className="tradingview-widget-container" style={{ marginTop: 60 }}>
            <div id="tradingview"></div>
            <div className="tradingview-widget-copyright">
                <a href="https://www.tradingview.com/symbols/NASDAQ-AAPL/" rel="noopener" target="_blank">
                    <span className="blue-text">AAPL Chart </span>
                </a>
                by TradingView
            </div>
        </div>
    );
}