import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import useTranslation from "../../customHooks/translations";
import PaymentTemplate from "../../templates/Payment";
import Image from "../../../assets/NAF1Logo.JPG";
import { Element, AmountElement, AggreementEl } from "./element";
import { currencyFormat, toTitleCase } from "../../../utils/util";
import { paymentProcess, paymentResult } from "../../../reducers/feature/payment/action";
import { makeSelectPaymentResult } from "../../../reducers/feature/payment/selector";
import Alert from "../../ui/molecules/Alert";
import countryList from "../../../utils/countryList";

export default function PaymentPage() {
    const { payment, requiredErrorTxt, okButtonTxt } = useTranslation();
    const { state } = useLocation();
    const { selected: paymentItem, packageList } = state;
    const formsData = Element(paymentItem, packageList, payment.forms, countryList);
    const [forms, setForms] = useState(JSON.parse(JSON.stringify(formsData)));
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [amountEl, setAmountEl] = useState(JSON.parse(JSON.stringify(AmountElement)));
    const aggreementElement = AggreementEl(payment.forms);
    const [aggreementEl, setAggreementEl] = useState(JSON.parse(JSON.stringify(aggreementElement)));
    const [rateText, setRateText] = useState("");

    const dispatch = useDispatch();
    const paymentResponse = useSelector(makeSelectPaymentResult());

    const setAgreement = (_, price, currency) => {
        const newCurrency = currency || amountEl[0].value;
        const amount = `${currencyFormat(price)} ${newCurrency.toUpperCase()}`;
        
        const newAggreementEl = JSON.parse(JSON.stringify(aggreementEl));
        newAggreementEl.label = payment.aggrementTxt.replace("$amount", amount);
        setAggreementEl(newAggreementEl);
    };

    const setAccountDetails = (newForms, activePayment, packagePrice) => {
        const accountDetailIdx = newForms.findIndex(x => x.id === "accountDetail");
        const options = newForms[activePayment].options;
        options.forEach(option => {
            if (option.value === newForms[activePayment].value) {
                newForms[accountDetailIdx].label = option.description
                    .replace("$id", option.accountId)
                    .replace("$amount", currencyFormat(packagePrice));
            }
        });
    }

    const setCurrencyAmount = (price, currency) => {
        const newEl = JSON.parse(JSON.stringify(amountEl));
        
        if (currency) {
            newEl[0].value = currency;
        }
        
        const option = newEl[0].options.find(x => x.value === newEl[0].value);
        const newPrice = price * option.rate;
        newEl[1].value = currencyFormat(newPrice);
        setAmountEl(newEl);
        setRateText(`1 ${option.label} = ${option.rate} USD Indicative Rate`);
        setAgreement(null, newPrice, newEl[0].value)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        setSubmitDisabled(false);
    }, []);

    useEffect(() => {
        if (paymentResponse && paymentResponse.status?.toLowerCase() === "success") {
            window.scrollTo(0, 0);
            setShowAlert(true);

            const resetForms = JSON.parse(JSON.stringify(formsData));
            const { value } = resetForms.find(x => x.id === "totalTransfer");
            const option = amountEl[0].options.find(x => x.value === amountEl[0].value);
            const price = value * option.rate;
            setAgreement(resetForms, price);

            const usdtIdx = resetForms.findIndex(x => x.id === "usdt");
            setAccountDetails(resetForms, usdtIdx, value);
            setForms(resetForms);

            setSubmitDisabled(false);

            dispatch(paymentResult(null));
        }
    }, [paymentResponse]);

    useEffect(() => {
        //Update label text when translation changes and keep the value
        const newForms = JSON.parse(JSON.stringify(formsData));
        for (let index = 0; index < forms.length; index++) {
            if (newForms[index].required) {
                newForms[index].value = forms[index].value;
                newForms[index].error = forms[index].error;
                newForms[index].helperText = forms[index].helperText !== "" ? requiredErrorTxt : "";
            }
        }

        const usdtIdx = newForms.findIndex(x => x.id === "usdt");
        const accountDetailIdx = newForms.findIndex(x => x.id === "accountDetail");
        newForms[accountDetailIdx].label = newForms[usdtIdx].options[0].description
            .replace("$id", newForms[usdtIdx].options[0].accountId)
            .replace("$amount", currencyFormat(paymentItem.price));
        newForms[accountDetailIdx].isDisplay = false;

        const option = amountEl[0].options.find(x => x.value === amountEl[0].value);
        const price = paymentItem.price * option.rate;
        setAgreement(newForms, price);
        setForms(newForms);
        setCurrencyAmount(paymentItem.price);
    }, [payment]); // eslint-disable-line react-hooks/exhaustive-deps

    const onClickPaymentHandler = () => {
        const currentForms = JSON.parse(JSON.stringify(forms));
        var isErrorFound = false;

        const packageName = currentForms.find(x => x.id === "packageName");
        const paymentMethod = currentForms.find(x => x.id === "paymentMethod");
        const packageIdx = packageList.findIndex(x => x.id === packageName.value);

        var minDeposit = packageList[packageIdx].minDeposit;
        var sign = "$";

        if (paymentMethod.value === "usdt") {
            sign = "USDT ";
        }

        for (let i = 0; i < currentForms.length; i++) {
            var errorMessage = requiredErrorTxt;
            var isError = false;

            if (currentForms[i].required && currentForms[i].value === "") {
                isError = true;
            } else if (currentForms[i].id === "deposit" && parseInt(currentForms[i].value) < minDeposit) {
                isError = true;
                errorMessage = `Your deposit must be at least ${sign}${currencyFormat(minDeposit)}`;
            }

            if (isError) {
                currentForms[i].helperText = errorMessage;
                currentForms[i].error = true;
                isErrorFound = true;
            }
        }
        if (isErrorFound) {
            setForms(currentForms);
        } else {
            const reqBody = {};
            const paymentMethod = currentForms.find(x => x.id === "paymentMethod");
            currentForms.forEach(element => {
                if (paymentMethod.value === element.id) {
                    reqBody["paymentType"] = element.value;
                } else if (element.value) {
                    const value = element.id === "packageName" ? element.value.replace("_", " ") : element.value;
                    reqBody[element.id] = value;
                }
            });

            if (typeof reqBody.totalTransfer === "string" && reqBody.totalTransfer.includes(",")) {
                reqBody.totalTransfer = parseInt(reqBody.totalTransfer.replace(",", ""));
            }

            delete reqBody.paymentMethod;
            delete reqBody.paymentType;
            delete reqBody.paypal;

            reqBody.packageName = toTitleCase(reqBody.packageName);

            const option = amountEl[0].options.find(x => x.value === amountEl[0].value);
            reqBody.totalTransfer = `${currencyFormat(reqBody.totalTransfer * option.rate)} ${option.label}`;

            const country = countryList.find(x => x.value === reqBody.buyerCountry);
            reqBody.buyerCountry = country.label;

            dispatch(paymentProcess(reqBody));
        }
    };

    const onChangeHandler = (index, value, id) => {
        if (id === "currency") {
            let packagePrice = 0;
            const packageNameIdx = forms.findIndex(x => x.id === "packageName");
            packageList.forEach(element => {
                if (element.id === forms[packageNameIdx].value) {
                    packagePrice = element.price;
                }
            });
            setCurrencyAmount(packagePrice, value)
        } else {
            onFormsChangeHandler(index, value, id);
        }
    }

    const onFormsChangeHandler = (index, value, id) => {
        const newForms = JSON.parse(JSON.stringify(forms));
        const prevVal = newForms[index].value;

        newForms[index].value = value;
        newForms[index].error = false;
        newForms[index].helperText = "";

        var packagePrice = 0;

        const packageNameIdx = newForms.findIndex(x => x.id === "packageName");
        packageList.forEach(element => {
            if (element.id === newForms[packageNameIdx].value) {
                packagePrice = element.price;
            }
        });

        if (id === "packageName" || id === "paymentMethod" || id === "deposit") {
            var depositStr = id === "deposit" ? value : "";

            for (var i = 0; i < newForms.length; i++) {
                if (newForms[i].id === value && value !== "paypal") {
                    newForms[i].isDisplay = true;
                }
                if (newForms[i].id === prevVal) {
                    newForms[i].isDisplay = false;
                }

                if (id === "packageName" || id === "paymentMethod") {
                    if (newForms[i].id === "price") {
                        newForms[i].value = currencyFormat(packagePrice);
                    }

                    if (newForms[i].id === "totalTransfer") {
                        newForms[i].value = currencyFormat(packagePrice);
                    }

                    if (newForms[i].id === "deposit") {
                        depositStr = newForms[i].value;
                    }
                }

                if ((id === "packageName" || id === "deposit") && newForms[i].id === "totalTransfer") {
                    const deposit = depositStr !== "" ? parseInt(depositStr) : 0;
                    newForms[i].value = currencyFormat(deposit + packagePrice);
                }
            }
        }

        if (id === "paymentMethod") {
            var andorments = "$";

            if (value === "usdt") {
                andorments = "USDT";
            }

            const totalTransferIdx = newForms.findIndex(x => x.id === "totalTransfer");
            newForms[totalTransferIdx].andorments = andorments;

            const depositIdx = newForms.findIndex(x => x.id === "deposit");
            if (depositIdx >= 0) {
                newForms[depositIdx].andorments = andorments;

                if (id === "paymentMethod" || id === "packageName") {
                    const depositIdx = newForms.findIndex(x => x.id === "deposit");
                    newForms[depositIdx].error = false;
                    newForms[depositIdx].helperText = "";
                }
            }
        }

        if (id === "usdt" || id === "paypal" || id === "paymentMethod" || id === "packageName") {
            const usdtIdx = newForms.findIndex(x => x.id === "usdt");
            const localBankIdx = newForms.findIndex(x => x.id === "paypal");

            var andorments = "$";
            var activePayment = localBankIdx;

            const paymentMethod = newForms.find(x => x.id === "paymentMethod");

            if (paymentMethod.value === "usdt") {
                andorments = "USDT";
                activePayment = usdtIdx;
            }

            setAccountDetails(newForms, activePayment, packagePrice);
            setCurrencyAmount(packagePrice);
        }

        if (id === "checkBoxId") {
            setSubmitDisabled(value);
        }

        const option = amountEl[0].options.find(x => x.value === amountEl[0].value);
        const price = packagePrice * option.rate;

        setAgreement(newForms, price);
        setForms(newForms);
    };

    const checkboxHandler = (value) => {
        const newEl = JSON.parse(JSON.stringify(aggreementEl));
        newEl.value = value;
        setSubmitDisabled(value);
        setAggreementEl(newEl);
    }

    const data = {
        title: payment.title,
        submitBtnTxt: payment.submitBtnTxt,
        image: Image,
        element: forms
    };

    const alertUI = () => (
        <Alert
            title={payment.paymentSuccessTxt}
            buttonTxt={okButtonTxt}
            onClick={() => setShowAlert(false)}
        />
    );

    return (
        <>
            {showAlert && alertUI()}
            <PaymentTemplate
                data={data}
                onClick={onClickPaymentHandler}
                onChange={onChangeHandler}
                submitDisabled={!submitDisabled}
                amountEl={amountEl}
                aggreementEl={aggreementEl}
                checkboxHandler={checkboxHandler}
                rateText={rateText}
            />
        </>
    );
}