import styled from "styled-components";
import TextField from '@mui/material/TextField';

const InputStyle = ({ height, width, textalign }) => ({
  '& .MuiInputBase-root': {
    width: width ?? 'auto',
    height: height ?? 'auto',
  },
  '& label.MuiInputLabel-shrink': {
  },
  '& .MuiFormHelperText-root': {
    color: 'red'
  },
  '& .MuiSelect-outlined': {
    textAlign: textalign ?? 'center'
  }
});
const Input = styled(TextField)(InputStyle);

export { Input };