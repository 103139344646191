import { REGISTER_PROCESS, REGISTER_PROCESS_RESULT } from "../../constants";

export function registerProcess(postBody) {
    return {
        type: REGISTER_PROCESS,
        postBody
    };
}

export function registerResult(data) {
    return {
        type: REGISTER_PROCESS_RESULT,
        result: data
    };
}