import React from 'react';
import { Container, Wrapper, LeftContainer, Label } from './styles';
import NavHeader from '../../molecules/Header/NavHeader';
import useTranslation from '../../../customHooks/translations';

export default function Header() {
    const translation = useTranslation();
    return (
        <Container>
            <Wrapper>
                <LeftContainer>
                    <Label>{translation.header.title}</Label>
                </LeftContainer>
                <NavHeader />
            </Wrapper>
        </Container>
    );
}