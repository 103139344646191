import React from 'react';
import {
    Container, Title, Label, Image, Button,
    FormWrapper, Divider, Rounded, OptionsContainer
} from "./styles";

export default function RegisterCard(props) {
    const { data, margin } = props;
    return (
        <Container margin={margin}>
            <Image src={data.logo} onClick={() => window.open(data.url, "_blank")} />
            <Title>{data.title}</Title>
            <Title>{data.subTitle}</Title>
            {data.forms.map((item, index) => {
                return (
                    <div key={index.toString()}>
                        <FormWrapper>
                            <Rounded />
                            <Label>{item.key ?? ""}{item.value}</Label>
                        </FormWrapper>
                        {item.options && (
                            <OptionsContainer>
                                {item.options.map((option, optIdx) => {
                                    return (
                                        <div key={optIdx.toString()}>
                                            <FormWrapper>
                                                <Rounded />
                                                <Label>{option.value}</Label>
                                            </FormWrapper>
                                            {option.options && (
                                                <OptionsContainer>
                                                    {option.options.map((childOpt, childOptId) => {
                                                        return (
                                                            <FormWrapper key={childOptId.toString()}>
                                                                <Rounded />
                                                                <Label>{childOpt.value}</Label>
                                                            </FormWrapper>
                                                        );
                                                    })}
                                                </OptionsContainer>
                                            )}
                                        </div>
                                    );
                                })}
                            </OptionsContainer>
                        )}
                    </div>
                );
            })}
            <div style={{ marginTop: 15, marginBottom: 15 }}>
                <Button variant="contained" onClick={() => window.open(data.url, "_blank")}>{data.buttonText}</Button>
            </div>
            <Divider />
            <Label>{data.footNote}</Label>
        </Container>
    );
}

RegisterCard.defaultProps = {
    data: {},
    margin: "auto"
};