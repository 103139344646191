import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import './App.css';
import Routes from "./components/NafRoutes";
import Header from "./components/ui/organisms/Header";
import Fab from '@mui/material/Fab';
import WhatsappIcon from "./assets/whatsapp_icon.png";
import Loader from "./components/ui/molecules/Loader";
import { store } from "./reducers/stores";

const fabStyle = {
  position: 'sticky',
  bottom: 20,
  right: 35,
  left: window.innerWidth / 1.1,
  zIndex: 1,
};

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Loader />
        <Header />
        <div className="Body-Container">
          <Routes />
        </div>
        <Fab sx={fabStyle} onClick={() => window.open("https://api.whatsapp.com/send?phone=447401192431")}>
          <img src={WhatsappIcon} width={56} height={56} />
        </Fab>
      </Router>
    </Provider>
  );
}

export default App;
