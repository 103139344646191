import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from "../../customHooks/translations";
import RegisterTemplate from "../../templates/Register";
import Image from "../../../assets/NAF1Logo.JPG";
import Element from "./element";
import { registerProcess, registerResult } from "../../../reducers/feature/register/action";
import { makeSelectRegisterResult } from "../../../reducers/feature/register/selector";
import Alert from "../../ui/molecules/Alert";

export default function RegisterPage() {
    const dispatch = useDispatch();
    const registerResponse = useSelector(makeSelectRegisterResult());

    const { register, requiredErrorTxt, okButtonTxt } = useTranslation();
    const { formData, agreement } = Element(register);
    const [forms, setForms] = useState(JSON.parse(JSON.stringify(formData)));
    const [aggrementEl, setAggrementEl] = useState(JSON.parse(JSON.stringify(agreement)));
    const [disabledSubmit, setDisabledSubmit] = useState(true);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        setDisabledSubmit(true);
    }, []);

    useEffect(() => {
        //Update label text when translation changes and keep the value
        const newForms = JSON.parse(JSON.stringify(formData));
        for (let index = 0; index < forms.length; index++) {
            const child = forms[index];
            for (let j = 0; j < child.length; j++) {
                newForms[index][j].value = forms[index][j].value;
                newForms[index][j].error = forms[index][j].error;
                newForms[index][j].helperText = forms[index][j].helperText !== "" ? requiredErrorTxt : "";
            }
        }
        setForms(newForms);

        const newAggrementEl = JSON.parse(JSON.stringify(agreement));
        newAggrementEl.value = aggrementEl.value;
        setAggrementEl(newAggrementEl);
    }, [register]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (registerResponse && registerResponse.status?.toLowerCase() === "success") {
            window.scrollTo(0, 0);
            const newForms = JSON.parse(JSON.stringify(formData));
            setForms(newForms);
            setDisabledSubmit(true);
            setShowAlert(true);

            const resetAggrementEl = JSON.parse(JSON.stringify(agreement));
            setAggrementEl(resetAggrementEl);
            dispatch(registerResult(null));
        }
    }, [registerResponse]);

    const onChangeHandler = (position, value) => {
        if (position === -1) {
            const newAggrementEl = JSON.parse(JSON.stringify(agreement));
            newAggrementEl.value = value;
            setAggrementEl(newAggrementEl);
            setDisabledSubmit(!value);
        } else {
            const { row, col } = position;
            const newForms = JSON.parse(JSON.stringify(forms));
            newForms[row][col].value = value;
            newForms[row][col].error = false;
            newForms[row][col].helperText = "";
            setForms(newForms);
        }
    };

    const onClickSubmitHandler = () => {
        const currentForms = JSON.parse(JSON.stringify(forms));
        var isErrorFound = false;
        for (let i = 0; i < currentForms.length; i++) {
            const child = currentForms[i];
            for (let j = 0; j < child.length; j++) {
                if (currentForms[i][j].required && currentForms[i][j].value === "") {
                    currentForms[i][j].helperText = requiredErrorTxt;
                    currentForms[i][j].error = true;
                    isErrorFound = true;
                }
            }
        }

        if (isErrorFound) {
            setForms(currentForms);
        } else {
            var reqBody = {};
            currentForms.forEach(item => {
                item.forEach(child => {
                    reqBody[child.id] = child.value;
                });
            });
            dispatch(registerProcess(reqBody));
        }
    };

    const onClickCancelHandler = () => {
        const resetForms = JSON.parse(JSON.stringify(formData));
        setForms(resetForms);

        const newAggrementEl = JSON.parse(JSON.stringify(agreement));
        newAggrementEl.value = false;
        setAggrementEl(newAggrementEl);
        setDisabledSubmit(true);
    };

    const alertUI = () => (
        <Alert
            title={register.registerSuccessTxt}
            buttonTxt={okButtonTxt}
            onClick={() => setShowAlert(false)}
        />
    );

    return (
        <>
            {showAlert && alertUI()}
            <RegisterTemplate
                forms={forms}
                title={register.title}
                aggrement={aggrementEl}
                image={Image}
                disabledSubmit={disabledSubmit}
                submitBtnTxt={register.submitBtnTxt}
                onClickSubmit={onClickSubmitHandler}
                cancelBtnTxt={register.cancelBtnTxt}
                onClickCancel={onClickCancelHandler}
                onChange={onChangeHandler}
            />
        </>
    );
}