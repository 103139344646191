import React from 'react';
import RegisterCard from '../../ui/organisms/RegisterCard';
import {
    Container, Title, SubTitle, RegisterCardContainer,
    PackageCardContainer, AffiliateContainer,
    Label, AffiliatListContainer, HeaderContainer, Wrapper
} from './styles';
import PackageCard from '../../ui/organisms/PackageCard';
import XAUUSDChart from "../../ui/molecules/XAUUSDChart"

export default function HomeTemplates(props) {
    const { data, brokerList, packageCards,
        onClickPackage,
        registerRef, packageRef,
    } = props;

    return (
        <Container>
            <HeaderContainer>
                <Title>{data.title}</Title>
                <SubTitle className='BackgroundContainer'>{data.subTitle}</SubTitle>
            </HeaderContainer>
            <RegisterCardContainer ref={registerRef}>
                {brokerList.length > 0 && brokerList.map((item, index) =>
                    <RegisterCard key={index.toString()} data={item} />
                )}
            </RegisterCardContainer>
            <PackageCardContainer ref={packageRef}>
                {packageCards.length > 0 && packageCards.map((item, index) =>
                    <PackageCard
                        key={index.toString()}
                        data={item}
                        isLast={index === packageCards.length - 1}
                        onClick={() => onClickPackage(item)}
                    />
                )}
            </PackageCardContainer>
            <AffiliateContainer>
                <Wrapper className='BackgroundContainer' marginTop="20px">
                    {packageCards.map((item, index) => {
                        return (
                            <AffiliatListContainer key={`description-${index.toString()}`}>
                                <SubTitle padding={"0 0 10px 0"} color="gold">{item.name}</SubTitle>
                                <Label>{item.description}</Label>
                            </AffiliatListContainer>
                        );
                    })}
                </Wrapper>
            </AffiliateContainer>
            <XAUUSDChart />
        </Container>
    );
}

HomeTemplates.defaultProps = {
    data: {},
    brokerList: [],
    packageCards: [],
    profitList: [],
    profitList2: [],
    affiliate: {},
    registerRef: null,
    packageRef: null,
    profitRef: null,
};