import React from "react";
import { Card, Wrapper, CardWrapper, Title, Label } from "./styles";
import Button from "../../atoms/Button";

export default function PackageCard(props) {
    const { data, isLast, onClick } = props;
    return (
        <Card marginRight={isLast ? "auto" : "50px"}>
            <Wrapper />
            <CardWrapper>
                <Title>{data.name}</Title>
                <Label>{`Price : $${data.price}`}</Label>
                <Button text={data.buttonTxt} onClick={onClick} />
            </CardWrapper>
        </Card>
    );
}

PackageCard.defaultProps = {
    data: {}
}