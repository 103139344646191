import Constants from "../../Constants";

const Element = (register) => {
    const width = "270px";
    const height = "70px";
    const formData = [
        [
            {
                type: Constants.INPUT_TYPE,
                label: register.firstName,
                value: "",
                id: "firstName",
                required: true,
                error: false,
                helperText: "",
                width,
                height,
            },
            {
                type: Constants.INPUT_TYPE,
                label: register.lastName,
                value: "",
                id: "lastName",
                required: true,
                error: false,
                helperText: "",
                width,
                height,
            },
            {
                type: Constants.INPUT_TYPE,
                label: register.email,
                value: "",
                id: "email",
                required: true,
                error: false,
                helperText: "",
                width,
                height,
            },
            {
                type: Constants.INPUT_TYPE,
                label: register.phone,
                value: "",
                id: "phone",
                required: true,
                error: false,
                helperText: "",
                width,
                height,
            },
            {
                type: Constants.INPUT_TYPE,
                label: register.referall,
                value: "",
                id: "linkName",
                error: false,
                helperText: "",
                width,
                height,
                disabled: false
            },
            {
                type: Constants.INPUT_ANDORMENTS,
                label: register.amount,
                value: "",
                id: "depositNumber",
                inputType: "number",
                andorments: "$",
                required: true,
                error: false,
                helperText: "",
                width,
                height,
            }
        ],
        [
            {
                type: Constants.INPUT_TYPE,
                label: register.tradingTxt,
                value: "",
                id: "tradName",
                required: true,
                error: false,
                helperText: "",
                width,
                height,
            },
            {
                type: Constants.INPUT_TYPE,
                label: register.tradingPasswordTxt,
                value: "",
                inputType: "password",
                id: "tradPassword",
                required: true,
                error: false,
                helperText: "",
                width,
                height,
            },
            {
                type: Constants.INPUT_TYPE,
                label: register.investorPasswordTxt,
                value: "",
                inputType: "password",
                id: "invesPassword",
                required: true,
                error: false,
                helperText: "",
                width,
                height,
            },
            {
                type: Constants.INPUT_TYPE,
                label: register.serverTxt,
                value: "",
                id: "serverName",
                required: true,
                error: false,
                helperText: "",
                width,
                height,
            },
            {
                type: Constants.SELECT_TYPE,
                label: register.brokerTxt,
                value: "ZFX",
                id: "brokerName",
                width,
                height,
                helperText: "",
                textalign: "justify",
                options: [
                    {
                        value: "ZFX",
                        label: "ZFX"
                    },
                    {
                        value: "JUST",
                        label: "JUST Forex"
                    },
                    {
                        value: "FBS",
                        label: "FBS"
                    }
                ]
            },
            {
                type: Constants.INPUT_TYPE,
                label: register.passwordPortalTxt,
                value: "",
                inputType: "password",
                id: "portalPassword",
                required: true,
                error: false,
                helperText: "",
                width,
                height,
            }
        ]
    ];

    const agreement = {
        type: Constants.CHECKBOX_TYPE,
        id: "checkBoxId",
        label: register.aggrementTxt,
        value: false,
        margin: "0 20px 50px 20px"
    }

    return { formData, agreement };
}

export default Element;