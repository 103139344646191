import React from 'react';
import { Input } from './styles';
import InputAdornment from '@mui/material/InputAdornment';

export default function InputAndorments(props) {
    const {
        id, label, value, onChange,
        helperText, error, andorment, type, required,
        height, width, disabled, textalign
    } = props;
    return (
        <Input
            id={id}
            label={label}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            helperText={helperText}
            error={error}
            type={type}
            required={required}
            height={height}
            width={width}
            disabled={disabled}
            textalign={textalign ?? "center"}
            InputProps={{
                startAdornment: <InputAdornment position="start">{andorment}</InputAdornment>,
            }}
        />
    );
}

InputAndorments.defaultProps = {
    id: "",
    label: "",
    value: "",
    onChange: () => { },
    helperText: "",
    error: false,
    andorment: "",
    type: "text",
    required: false,
    height: "auto",
    width: "auto",
    disabled: false,
    textalign: "center"
};