import produce from 'immer';
import { PAYMENT_PROCESS_RESULT } from "../../constants";

// The initial state of the register
export const initialState = {
    paymentResult: null
};

const paymentReducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case PAYMENT_PROCESS_RESULT:
            draft.paymentResult = action.result;
            break;
        default:
            break;
    }
});

export default paymentReducer;
