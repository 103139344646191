import React from 'react';
import JButton from './styles';

export default function Button(props) {
    return <JButton
        variant={props.type}
        onClick={props.onClick}
        width={props.width}
        disabled={props.disabled}
    >
        {props.text}
    </JButton>
}

Button.defaultProps = {
    text: "",
    width: "",
    type: "contained",
    onClick: () => { }
}