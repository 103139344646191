import produce from 'immer';
import { REGISTER_PROCESS_RESULT } from "../../constants";

// The initial state of the register
export const initialState = {
    registerResult: null
};

const registerReducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case REGISTER_PROCESS_RESULT:
            draft.registerResult = action.result;
            break;
        default:
            break;
    }
});

export default registerReducer;
