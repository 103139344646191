import ComputerIcon from "../../../assets/computer_icon.png";
import SmartphoneIcon from "../../../assets/smartphone_icon.png";

export const Element = (translations) => {
    return [
        [
            {
                title: translations.metatrader4Txt,
                subTitle: translations.forPCTxt,
                image: ComputerIcon,
                buttonTxt: translations.downloadTxt,
                link: "https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/mt4setup.exe?utm_source=www.metatrader4.com&utm_campaign=download"
            },
            {
                title: translations.metatrader4Txt,
                subTitle: translations.forMacTxt,
                image: ComputerIcon,
                buttonTxt: translations.downloadTxt,
                link: "https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.dmg?utm_source=www.metatrader4.com&utm_campaign=download.mt5.macos"
            },
            {
                title: translations.metatrader4Txt,
                subTitle: translations.forAndroidTxt,
                image: SmartphoneIcon,
                buttonTxt: translations.downloadTxt,
                link: "https://download.mql5.com/cdn/mobile/mt4/android?utm_source=www.metatrader4.com&utm_campaign=download"
            },
            {
                title: translations.metatrader4Txt,
                subTitle: translations.forIOSTxt,
                image: SmartphoneIcon,
                buttonTxt: translations.downloadTxt,
                link: "https://download.mql5.com/cdn/mobile/mt4/ios?utm_source=www.metatrader4.com&utm_campaign=download"
            }
        ]
    ];
};