import EnglishIcon from "../assets/english_icon.jpg";
import ItaliaIcon from "../assets/italia_icon.jpg";
import FranceIcon from "../assets/france_icon.jpg";
import GermanyIcon from "../assets/germany_icon.jpg";
import IndonesiaIcon from "../assets/indonesia_icon.png";
import ArabicIcon from "../assets/united-arab-emirates-icon.png";
import TurkeyIcon from "../assets/turkey_icon.png";
import ChinaIcon from "../assets/china_icon.png";
import JapanIcon from "../assets/japan_icon.png";

const languageObj = [
    {
        name: "English",
        id: "en",
        image: EnglishIcon
    },
    {
        name: "Italia",
        id: "it",
        image: ItaliaIcon
    },
    {
        name: "France",
        id: "fr",
        image: FranceIcon
    },
    {
        name: "Germany",
        id: "de",
        image: GermanyIcon
    },
    {
        name: "Arabic",
        id: "ar",
        image: ArabicIcon
    },
    {
        name: "Turkey",
        id: "tr",
        image: TurkeyIcon
    },
    {
        name: "China",
        id: "zh",
        image: ChinaIcon
    },
    {
        name: "Japan",
        id: "ja",
        image: JapanIcon
    },
    {
        name: "Indonesia",
        id: "id",
        image: IndonesiaIcon
    },
];

export default languageObj;