import React from "react";
import Constants from "../../../Constants";
import InputField from "./InputField";
import InputAndorments from "./InputAndorments";
import InputSelect from "./InputSelect";

export default function TextField(props) {
    const { data, id, onChange } = props;
    const renderUI = () => {
        switch (data.type) {
            case Constants.INPUT_TYPE:
                return (
                    <InputField
                        id={id}
                        label={data.label}
                        value={data.value}
                        type={data.inputType ?? "text"}
                        required={data.required ?? false}
                        helperText={data.helperText ?? ""}
                        error={data.error ?? false}
                        width={data.width}
                        disabled={data.disabled ?? false}
                        textalign={data.textalign ?? "center"}
                        onChange={(value) => onChange(value)}
                    />
                )
            case Constants.INPUT_ANDORMENTS:
                return (
                    <InputAndorments
                        id={id}
                        label={data.label}
                        value={data.value}
                        andorment={data.andorments}
                        type={data.inputType ?? "text"}
                        required={data.required ?? false}
                        error={data.error ?? false}
                        width={data.width}
                        disabled={data.disabled ?? false}
                        helperText={data.helperText ?? ""}
                        textalign={data.textalign ?? "center"}
                        onChange={(value) => onChange(value)}
                    />
                )
            case Constants.SELECT_TYPE:
                return (
                    <InputSelect
                        id={id}
                        label={data.label}
                        value={data.value}
                        helperText={data.helperText ?? ""}
                        error={data.error ?? false}
                        onChange={(value) => onChange(value)}
                        options={data.options}
                        width={data.width}
                        disabled={data.disabled ?? false}
                        required={data.required ?? false}
                        textalign={data.textalign ?? "center"}
                    />
                )
            default:
                return <></>
        }
    };

    return renderUI();
};

TextField.defaultProps = {
    data: {},
    id: "",
    onChange: () => { }
};