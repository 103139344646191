import Constants from "../../Constants";

const width = "30em";
const height = "auto";

const Element = (data, packageList, translation, countryList) => {
    return [
        {
            type: Constants.INPUT_TYPE,
            label: translation.fullNameTxt,
            value: "",
            id: "name",
            inputType: "text",
            required: true,
            error: false,
            helperText: "",
            width,
            height,
            isDisplay: true
        },
        {
            type: Constants.INPUT_TYPE,
            label: translation.emailTxt,
            value: "",
            id: "email",
            inputType: "text",
            required: true,
            error: false,
            helperText: "",
            width,
            height,
            isDisplay: true
        },
        {
            type: Constants.INPUT_TYPE,
            label: translation.phoneNumberTxt,
            value: "",
            id: "phonenumber",
            inputType: "text",
            required: true,
            error: false,
            helperText: "",
            width,
            height,
            isDisplay: true
        },
        {
            type: Constants.SELECT_TYPE,
            id: "buyerCountry",
            label: translation.buyerCountryTxt,
            value: "AL",
            width,
            height,
            isDisplay: true,
            options: countryList,
            textalign: "justify"
        },
        {
            type: Constants.SELECT_TYPE,
            id: "packageName",
            label: translation.packageNameTxt,
            value: data?.value ?? "",
            width,
            height,
            isDisplay: true,
            options: packageList,
            textalign: "justify"
        },
        {
            type: Constants.SELECT_TYPE,
            id: "paymentMethod",
            label: translation.paymentMethodTxt,
            value: `usdt`,
            width,
            height,
            required: false,
            isDisplay: false,
            textalign: "justify",
            options: [
                {
                    value: "usdt",
                    label: "USDT"
                },
                {
                    value: "paypal",
                    label: "Paypal"
                },
            ]
        },
        {
            type: Constants.SELECT_TYPE,
            id: "usdt",
            label: translation.usdtTxt,
            value: `usdttrc20`,
            width,
            height,
            required: false,
            isDisplay: false,
            textalign: "justify",
            options: [
                {
                    value: "usdttrc20",
                    label: "USDT TRC20",
                    accountId: "TXezYQKxQJsQkuuT1XBJPzL3mAnhnMg82R",
                    description: translation.usdtDescTxt
                },
                {
                    value: "usdtbep20",
                    label: "USDT BEP20",
                    accountId: "0xff833f6f6cec6de48e6ea46edbff265de48d38d8",
                    description: translation.usdtDescTxt
                }
            ]
        },
        {
            type: Constants.SELECT_TYPE,
            id: "paypal",
            label: "Paypal",
            value: `paypal`,
            width,
            height,
            required: false,
            isDisplay: false,
            textalign: "justify",
            options: [
                {
                    value: "paypal",
                    label: "Paypal",
                    description: "<a href='https://paypal.me/eatomic?country.x=ID&locale.x=id_ID' target='_blank'>https://paypal.me/eatomic?country.x=ID&locale.x=id_ID</a>"
                }
            ]
        },
        {
            type: Constants.LABEL_TYPE,
            label: "Account Information",
            id: "title",
            required: false,
            isDisplay: false,
            fontSize: "13px",
            margin: "-22px 22px",
            padding: "5px 15px 5px 10px",
            textalign: "left"
        },
        {
            type: Constants.LABEL_TYPE,
            label: "",
            id: "accountDetail",
            required: false,
            isDisplay: false,
            fontSize: "13px",
            margin: "22px",
            padding: "5px 15px 5px 10px",
            background: "lightgray",
            borderRadius: "5px",
            textalign: "left"
        },
        {
            type: Constants.INPUT_ANDORMENTS,
            label: translation.totalTransferTxt,
            value: data?.price ?? "0",
            id: "totalTransfer",
            inputType: "text",
            andorments: "USDT",
            error: false,
            helperText: "",
            width,
            height,
            isDisplay: false,
            required: false,
            disabled: true
        },
        {
            type: Constants.INPUT_TYPE,
            label: translation.linkTransferTxt,
            value: "",
            id: "receiptLink",
            inputType: "text",
            required: true,
            error: false,
            helperText: "",
            width,
            height,
            isDisplay: false,
            required: false,
        },
        {
            type: Constants.LABEL_TYPE,
            label: translation.linkTransferDescTxt,
            isDisplay: false,
            required: false,
            margin: "-22px 22px",
            padding: "5px 15px 15px 10px",
            fontSize: "13px"
        },
        {
            type: Constants.CHECKBOX_TYPE,
            id: "checkBoxId",
            label: translation.aggrementTxt,
            isDisplay: false,
            required: false,
            value: false,
            margin: "20px"
        }
    ];
};

const AmountElement = [
    {
        type: Constants.SELECT_TYPE,
        id: "currency",
        label: "Currency",
        value: "eur",
        width: "85px",
        height,
        isDisplay: true,
        options: [
            {
                value: "eur",
                label: "EUR",
                rate: 1.02
            },
            {
                value: "usd",
                label: "USD",
                rate: 1
            },
            {
                value: "gbp",
                label: "GBP",
                rate: 0.88
            },
            {
                value: "sgd",
                label: "SGD",
                rate: 1.43
            }
        ],
        textalign: "justify",
    },
    {
        type: Constants.INPUT_TYPE,
        label: "Amount",
        value: "",
        id: "amount",
        inputType: "text",
        required: false,
        error: false,
        helperText: "",
        width: "24.8em",
        height,
        isDisplay: true,
        disabled: true,
    }
]

const AggreementEl = (translation) => {
    return {
        type: Constants.CHECKBOX_TYPE,
        id: "checkBoxId",
        label: translation.aggrementTxt,
        isDisplay: false,
        value: false,
        margin: "20px"
    }
}

export { Element, AmountElement, AggreementEl };