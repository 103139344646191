import React from "react";
import { useSelector } from 'react-redux'
import { ProgressBar } from "./styles";
import Modal from "../../../templates/Modal";
import { makeSelectLoading } from "../../../../reducers/feature/generalSelector";

export default function Loader() {
    const isLoading = useSelector(makeSelectLoading());

    if (!isLoading) {
        return null;
    }

    return (
        <Modal>
            <ProgressBar />
        </Modal>
    );
}